//
// menu.scss
//

// Logo
.logo {
    display: block;
    line-height: 80px;
    filter: brightness(100%);

    span.logo-lg {
        display: block;
        height: 90px;
        margin-top: 15px;
        margin-bottom: 10px;
    }
    span.logo-sm {
        display: none;
    }
}

// Wrapper
.wrapper {
    height: 100%;
    overflow: hidden;
    width: 100%;
}

//Content Page
.content-page {
    margin-left: $leftbar-width;
    overflow: hidden;
    padding: 5px 20px;
    min-height: 80vh;
}

// Left Side-menu (Default dark)
.left-side-menu {
    width: $leftbar-width;
    z-index: 10;
    //background: $bg-leftbar;
    background-color: #f8fafd !important;
    bottom: 0;
    position: fixed;
    transition: all .2s ease-out;
    top: 0;
}

// Side-nav
.side-nav {
    .side-nav-link {
        color: #004a77;
        display: block;
        padding: 7px 12px;
        font-size: 1rem;
        position: relative;
        transition: all 0.4s;
        font-weight: 400;
        text-decoration: none !important;
        &:hover,
        &:focus,
        &:active {
            background-color: #e3e3e3;
            border-radius: 30px;
            text-decoration: none !important;
        }

        span {
            vertical-align: middle;
        }

        i {
            display: inline-block;
            line-height: 1rem;
            margin: 0 7px 0 3px;
            text-align: center;
            vertical-align: middle;
            width: 18px;
            font-size: 1.3rem;
        }

        &.active {
            background-color: #c3ecff;
            border-radius: 30px;
        }
    }

    .menu-arrow {
        transition: transform .15s;
        position: absolute;
        right: 10px;
        top:10px;
        display: inline-block;
        font-family: 'Material Design Icons';
        text-rendering: auto;
        line-height: 1.2rem;
        font-size: 1.5rem;
        transform: translate(0, 0);
        &:before {
            content: "\F142";
        }
    }

    .badge{
        margin-top: 3px;
    }

    .side-nav-item {
        &.active {
            > a {
                .menu-arrow {
                    transform: rotate(90deg);
                }
            }
        }
    }

    .side-nav-title {
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 15px;
        padding-bottom: 0px;
        letter-spacing: .05em;
        pointer-events: none;
        cursor: default;
        font-size: 0.8275rem;
        text-transform: uppercase;
        color: #b9b9b9;
        font-weight: $font-weight-bold;
    }
}

// Enlarge menu
.enlarged {}

// Body min-height set
body.enlarged {

}

@include media-breakpoint-down(md) {
    body {
        overflow-x: hidden;
    }
    .left-side-menu {
        box-shadow: $shadow;
        display: none;
        z-index: 10 !important;
    }
    .sidebar-enable {
        .left-side-menu {
            display: block;
        }
    }
    .navbar-nav.navbar-right {
        float: right;
    }
    .content-page {
        margin-left: 0 !important;
        padding: 5px 10px;
    }
    .enlarged {
        .left-side-menu {
            margin-left: -70px;
        }
    }
    .logo {
        span.logo-lg {
            display: block;
        }
        span.logo-sm {
            display: none;
        }
    }
}


// Light sidebar
.left-side-menu-light {
    background: $bg-leftbar-light;
}

.side-nav-light {
    .side-nav-link {
        color: $menu-item-light;
        &:hover,
        &:focus,
        &:active {
            color: $menu-item-light-hover;
        }

        &.active {
            color: $menu-item-light-active;
        }
    }

    .side-nav-title {
        color: $menu-item-light;
    }
}

/// Help-box
.help-box {
    border-radius: 5px;
    padding: 20px;
    margin: 65px 25px 25px;
    position: relative;
    background-color: lighten($primary,2.5%);
    .close-btn {
        position: absolute;
        right: 10px;
        top: 10px;
    }
}
// Light
.help-box-light {
    background-color: $gray-100;
}

// Layout -topnav
body[data-layout="topnav"] {
    .content-page {
        margin-left: 0 !important;
        padding: 0;
    }
}

// Layout-boxed
body.boxed-layout {
    background-color: $boxed-layout-bg;

    .wrapper {
        max-width: $boxed-layout-width;
        margin: 0 auto;
        background-color: $body-bg;
        box-shadow: $shadow;
    }
    &.enlarged {
        .footer {
            max-width: calc(#{$boxed-layout-width} - 70px);
        }
    }
}

// Scrollable Layout

@include media-breakpoint-up(xl) {
    .scrollable-layout {
        padding-bottom: 0;

        .wrapper {
            display: flex;
        }

        .left-side-menu {
            position: relative;
            min-width: $leftbar-width;
            max-width: $leftbar-width;
        }

        .content-page {
            margin-left: 0;
            width: 100%;
            padding-bottom: 60px;
        }
    }
}

// Detached Left sidebar

body[data-layout="detached"] {
    padding-bottom: 0;

    @include media-breakpoint-up(lg) {
        .container-fluid {
          max-width: 95%;
        }
    }
    .wrapper {
        display: flex;
        padding-top: 30px;
        overflow: inherit;
    }
    .content-page {
        margin-left: 0;
        overflow: hidden;
        padding: 0 15px 5px 30px;
        position: relative;
        margin-right: -15px;
        width: 100%;
        padding-bottom: 60px;
    }

    // Enlarge menu
    &.enlarged {
        .wrapper {
            // Side menu
            .left-side-menu {
                max-width: 70px;
                min-width: 70px;
                position: relative;
            }
            .leftbar-user {
                display: none;
            }

            // Content Page
            .content-page {
                margin-left: 0;
            }

            //Footer
            .footer {
                left: 0;
            }
        }
    }


    @include media-breakpoint-down(md) {
        &.sidebar-enable {
            .left-side-menu {
                position: fixed;
                left: 0;
                overflow-y: auto;
            }
        }
        .content-page {
            margin-left: 0 !important;
            padding: 0 10px 60px 10px;
        }
    }

}

.left-side-menu-detached {
    position: relative;
    background: $bg-detached-leftbar;
    min-width: $leftbar-width;
    max-width: $leftbar-width;
    box-shadow: $shadow;

    .side-nav-light {
        .side-nav-link {
            color: $menu-item-dark;
            &:hover,
            &:focus,
            &:active {
                color: $menu-item-dark-hover;
            }

            &.active {
                color: $menu-item-dark-active;
            }
        }

        .side-nav-title {
            color: $menu-item-dark;
        }
    }
}

.leftbar-user {
    background: url("../images/waves.png") no-repeat;
    padding: 30px 20px;
    text-align: center;

    .leftbar-user-name {
        font-weight: 700;
        color: $dark;
        margin-left: 12px;
        margin-top: 8px;
        display: block;
    }
}

.button-menu-mobile {

    .lines {
        width: 18px;
        display: block;
        position: relative;
        height: 16px;
        transition: all .5s ease;
        margin-top: 26px;
        margin-left: 10px;
    }
    span {
        height: 2px;
        width: 100%;
        background-color: rgba($white, 0.8);
        display: block;
        margin-bottom: 5px;
        transition: transform .5s ease;

        &:nth-of-type(2) {
            width: 24px;
        }
    }

    &.disable-btn {
        display: none;
    }
}
