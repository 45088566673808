//
// authentication.scss
//

// authentication pages background
body.authentication-bg {
    background-image: $auth-bg-pattern-img;
    background-size: cover;
    background-position: center;
}

.authentication-bg.enlarged,.auth-fluid-pages.enlarged {
    min-height: 100px;
}

// Logout page
.logout-icon {
    width: 140px;
}

// Authentication fluid
.auth-fluid {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 100vh;
    flex-direction: row;
    align-items: stretch;
    background: url("https://cnf.smf.mx/static/images/smf/uach.jpg") center;
    background-size: cover;

    .auth-fluid-form-box {
        max-width: 480px;
        border-radius: 0;
        z-index: 2;
        padding: 0rem 1rem;
        margin-top: -25px;
        background-color: $auth-bg;
        position: relative;
        width: 100%;
    }

    .auth-fluid-right {
        padding: 6rem 3rem;
        flex: 1;
        position: relative;
        color: $white;
        background-color: rgba($black,0.0);
    }
}

.auth-brand {
    margin-bottom: 1.5rem;
}

.auth-user-testimonial {
    position: absolute;
    margin: 0 auto;
    padding: 0 1.75rem;
    bottom: 1rem;
    left: 0;
    right: 0;

    p.lead {
        font-size: 1rem;
        margin: 0 auto 20px auto;
        max-width: None;
        color: $black;
        background-color: rgba(255,255,255,0.5);
    }
}

@include media-breakpoint-up(lg) {
    .auth-brand {

    }
}

@include media-breakpoint-down(md) {
    .auth-fluid {
        display: block;

        .auth-fluid-form-box {
            max-width: 100%;
            min-height: 100vh
        }

        .auth-fluid-right {
            display: none;
        }
    }
}
