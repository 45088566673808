//
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  padding-bottom: 15px;
  overflow-x: hidden;
  background-color: #f1f3f4 !important;
}

body p {
  text-align: justify !important;
}

.alert{
  border: none !important;
    -webkit-box-shadow: 0 0 14px 0 rgba(154,161,171,0.2) !important;
    box-shadow: 0 0 14px 0 rgba(154,161,171,0.2) !important;
    border-radius: 15px !important;
}

.page-item.active .page-link {
  color:#000000 !important;
}

.tasks .task-header {
    font-size: 1.5em;
}

.tasks {
  padding-bottom: 0px !important;
  margin-bottom: 11px !important;
}

.toast {
    max-width: 350px;
    overflow: hidden;
     font-size: 1rem !important;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,0.2);
    -webkit-box-shadow: 0 0.25rem 0.55rem rgba(0,0,0,.6);
    box-shadow: 0 0.25rem 0.55rem rgba(0,0,0,.6);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    opacity: 0;
    border-radius: 0.8rem;
}

.toast-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: .25rem .75rem;
    color: #000;
    background-color: rgba(255,255,255,.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0,0,0,0.2);
  border-radius: 0.8rem;
}

.badge {
  font-size: 1em;
}

.hvr-underline-from-left:before {
  background-color: #454ADE !important;
}



.hvr-underline-from-left {
  -webkit-box-shadow: 0 0 14px 0 rgba(154,161,171,.62) !important;
    box-shadow: 0 0 14px 0 rgba(154,161,171,.62) !important;
}
.badge {
  border-radius: 0.2rem;
}

.badge-warning {
    color: #000;
}

.dropzone {
    border-radius: 0.4rem !important;
}


.toast-header {
    background-color: #e9c46a !important;
    border-bottom: none !important;
    border-radius: 0rem !important;
}

.toast-header > strong {
  font-size: 1.4rem;
}

.toast-body {
    padding-top: 0rem !important;
}

.toast {
    max-width: 350px;
    overflow: hidden;
    font-size: 1rem!important;
    background-color: #e9c46a;
    color: #000;
    background-clip: padding-box;
    border: none;
    -webkit-box-shadow: 0 0.25rem 0.55rem rgba(0,0,0,.2);
    box-shadow: 0 0.25rem 0.55rem rgba(0,0,0,.2);
    backdrop-filter: none !important;
    opacity: 0;
    border-radius: 0.4rem;
}

.badge {
    font-size: 0.91em !important;
}

.table .action-icon {
    color: #ffffff !important;
    font-size: 1.6rem !important;
    display: inline-block;
    padding: 7px;
}

.modal-open .modal {
    background-color: rgba(0,0,0,0.5);
}


//Estilos personalizados
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

body{
  font-family: 'Lato', sans-serif !important;
}

.close{
  font-size: 2rem !important;
}

.breadcrumb-item.active {
    color: #000 !important;
}

.breadcrumb-item {
    font-weight: bold !important;
}

.breadcrumb-item+.breadcrumb-item::before {
    color: #000 !important;
}

body {
    font-size: 1rem !important;

}

.btn-danger{
  background-color: #e63946 !important;
    border-color: #e63946 !important;
}

.modal-header {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    border-bottom: none !important;
}

.modal-footer {
    padding-top: 0.2rem !important;
    border-top: none !important;
}

.modal-body {
    padding-bottom: 0.3rem !important;
}

.modal-open .modal {
    background-color: rgba(0,0,0,0.7) !important;
}


.alert-warning{
  background-color: #e9c46a !important;
  color: #000 !important;
}

.alert-info{
  background-color: #023e8a !important;
  color: #fff !important;
}

button.dt-button{
    font-weight: 400 !important;
    padding: .45rem .9rem !important;
    font-size: .942rem !important;
    line-height: 1.5 !important;
    border-radius: .3rem !important;
color: #343a40 !important;
    background-color: #e3eaef !important;
    border-color: #e3eaef !important;
}

thead th {
  background-color: rgba(240,240,240,0.8) !important;
    backdrop-filter: blur(5px);
    position: sticky;
    top: 0;
}

@media only screen and (min-width: 1025px) {
    .table{
      max-height: 600px;
  }
}



.table td{
    border-top: none !important;
  border-bottom: 1px solid #dbdbdb !important;
}

.table th {
    border-top: none !important;
  border-bottom: 4px solid #dbdbdb !important;
}

.dt-buttons{
  margin-bottom: 15px;
}