//
// page-head.scss
//


.page-title {
        font-size: 26px;
        margin: 0;
        line-height: 85px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: $page-title-color;
    }

.page-title-box {
    
.page-title {
    font-size: 27px;
    text-transform: capitalize;
    margin: 0;
    line-height: 50px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #181818 !important;
    font-weight: 300;
}
    .page-title-right {
        float: right;
        margin-top: 25px;
    }

    .breadcrumb {
        padding-top: 8px;
    }
}

.page-title-box-sm {
    .page-title {
        line-height: 1 !important;
        margin-bottom: 25px;
    }
    .page-title-right {
        float: right;
        margin-top: 0;
    }

    .breadcrumb {
        padding-top: 0;
        margin-top: -3px !important;
    }
}


.text-title {
    color: $text-title-color;

    &:hover {
        color: $text-title-color;
    }
}

@include media-breakpoint-down(sm) {
    .page-title-box {
        .page-title {
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 70px;
        }
        .breadcrumb {
            display: none;
        }
        .page-title-right {

        }
    }
}

@media (max-width: 419px) {
    .page-title-box .breadcrumb {
        display: none;
    }
}
