//
// metis-menu.scss
//

// Metis Menu Overwrite

.metismenu {
    padding: 0;

    li {
        list-style: none;
    }
    ul {
        padding: 0;
    }

    .collapse {
        display: none;
    }

    .collapse.in {
        display: block;
    }

    .collapsing {
        position: relative;
        height: 0;
        overflow: hidden;
        transition-timing-function: ease;
        transition-duration: .35s;
        transition-property: height, visibility;
    }
}

.side-nav-second-level,
.side-nav-third-level {
    li {
        a {
            text-decoration: none !important;
            padding: 5px 25px;
            padding-left: 45px;
            font-size: 1rem;
            color: #004a77;
            display: block;
            position: relative;
            transition: all 0.4s;
            &:focus,
            &:hover {
                color: #004a77;
            }

            .menu-arrow {
                line-height: 1.1rem;
            }
        }
        &.active {
            >a {
                color: #000;
            }
        }
    }
}


.side-nav-third-level {
    li {
        &.active {
            >a {
                color: #000;
            }
        }
    }
}

.side-nav-light {
    .side-nav-second-level,
    .side-nav-third-level {
        li {
            a {
                color: $menu-item-light;
                &:focus,
                &:hover {
                    color: $menu-item-light-hover;
                }
            }
            &.active {
                >a {
                    color: $menu-item-light-active;
                }
            }
        }
    }
}
.left-side-menu-detached {
    .side-nav-light {
        .side-nav-second-level,
        .side-nav-third-level {
            li {
                a {
                    color: $menu-item-dark;
                    &:focus,
                    &:hover {
                        color: $menu-item-dark-hover;
                    }
                }
                &.active {
                    >a {
                        color: $menu-item-dark-active;
                    }
                }
            }
        }
    }
}

.side-nav-third-level {
    li {
        a {
            padding: 5px 35px;
            padding-left: 60px;
        }
    }
}
